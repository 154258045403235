import type { PublicClientApplication } from '@azure/msal-browser'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { createBrowserRouter, Navigate } from 'react-router-dom'

import { isNotUndefined } from '@hcr/utils'

import {
  About,
  AboutMocks,
  Account,
  AccountEdit,
  AccountUnauthenticated,
  App,
  Booking,
  BookingDetails,
  BookingUnauthenticated,
  ContextMenu,
  ContextMenuDestinations,
  ContextMenuUnauthenticated,
  GomeddoBooking,
  GomeddoBookingUnauthenticated,
  History,
  HistoryDetailsAccommodation,
  HistoryDetailsActivity,
  HistoryDetailsTicket,
  Home,
  HomeUnauthenticated,
  LayoutNavigationError404,
  Onboarding,
  OnboardingCompletion,
  Owner,
  OwnerBenefitDetails,
  OwnerBenefits,
  OwnerDetails,
  OwnerHoldingBooking,
  OwnerHoldingBookingCancel,
  OwnerHoldingDetails,
  OwnerHoldingRental,
  OwnerHoldingWeeks,
  OwnerUnauthenticated,
  Restricted,
  RestrictedMsal,
  ServiceDetails,
  Services,
  TicketDetailsActivity,
  TicketDetailsPackage,
  TicketDetailsSingle,
} from './components'
import { Path } from './models'

interface CreateRouterOptions {
  msalInstance: PublicClientApplication
  mswInstance: ServiceWorkerRegistration | undefined
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

export const createRouter = ({ msalInstance, mswInstance }: CreateRouterOptions) =>
  sentryCreateBrowserRouter([
    {
      element: <App msalInstance={msalInstance} />,
      children: [
        {
          path: Path.About,
          element: <About />,
        },
        {
          path: Path.AboutMocks,
          element: (
            <Restricted isAllowed={isNotUndefined(mswInstance)} fallback={<Navigate to={Path.About} replace />} />
          ),
          children: [
            {
              index: true,
              element: <AboutMocks />,
            },
          ],
        },
        {
          path: Path.Account,
          element: <RestrictedMsal isAuthenticated={true} fallback={<AccountUnauthenticated />} />,
          children: [
            {
              index: true,
              element: <Account />,
            },
          ],
        },
        {
          path: Path.AccountEdit,
          element: <RestrictedMsal isAuthenticated={true} fallback={<Navigate to={Path.Account} replace />} />,
          children: [
            {
              index: true,
              element: <AccountEdit />,
            },
          ],
        },
        {
          path: Path.Booking,
          element: <RestrictedMsal isAuthenticated={true} fallback={<BookingUnauthenticated />} />,
          children: [
            {
              index: true,
              element: <Booking />,
            },
          ],
        },
        {
          path: Path.BookingDetails,
          element: <RestrictedMsal isAuthenticated={true} fallback={<Navigate to={Path.Booking} replace />} />,
          children: [
            {
              index: true,
              element: <BookingDetails />,
            },
          ],
        },
        {
          path: Path.ContextMenu,
          element: <RestrictedMsal isAuthenticated={true} fallback={<ContextMenuUnauthenticated />} />,
          children: [
            {
              index: true,
              element: <ContextMenu />,
            },
          ],
        },
        {
          path: Path.ContextMenuDestinations,
          element: <ContextMenuDestinations />,
        },
        {
          path: Path.GomeddoBooking,
          element: <RestrictedMsal isAuthenticated={true} fallback={<GomeddoBookingUnauthenticated />} />,
          children: [
            {
              index: true,
              element: <GomeddoBooking />,
            },
          ],
        },
        {
          path: Path.History,
          element: <RestrictedMsal isAuthenticated={true} fallback={<Navigate to={Path.Account} replace />} />,
          children: [
            {
              index: true,
              element: <History />,
            },
            {
              path: Path.HistoryDetailsAccommodation,
              element: <HistoryDetailsAccommodation />,
            },
            {
              path: Path.HistoryDetailsActivity,
              element: <HistoryDetailsActivity />,
            },
            {
              path: Path.HistoryDetailsTicket,
              element: <HistoryDetailsTicket />,
            },
          ],
        },
        {
          path: Path.Home,
          element: <RestrictedMsal isAuthenticated={true} fallback={<HomeUnauthenticated />} />,
          children: [
            {
              index: true,
              element: <Home />,
            },
          ],
        },
        {
          path: Path.Services,
          children: [
            {
              index: true,
              element: <Services />,
            },
            {
              path: Path.ServiceDetails,
              element: <ServiceDetails />,
            },
          ],
        },
        {
          path: Path.Onboarding,
          element: <RestrictedMsal isAuthenticated={false} fallback={<Navigate to={Path.Home} replace />} />,
          children: [
            {
              index: true,
              element: <Onboarding />,
            },
            {
              path: Path.OnboardingCompletion,
              element: <OnboardingCompletion />,
            },
          ],
        },
        {
          path: Path.Owner,
          element: <RestrictedMsal isAuthenticated={true} fallback={<OwnerUnauthenticated />} />,
          children: [
            {
              index: true,
              element: <Owner />,
            },
            {
              path: Path.OwnerDetails,
              element: <OwnerDetails />,
            },
            {
              path: Path.OwnerBenefits,
              element: <OwnerBenefits />,
            },
            {
              path: Path.OwnerBenefitDetails,
              element: <OwnerBenefitDetails />,
            },
            {
              path: Path.OwnerHoldingDetails,
              element: <OwnerHoldingDetails />,
            },
            {
              path: Path.OwnerHoldingWeeks,
              element: <OwnerHoldingWeeks />,
            },
            {
              path: Path.OwnerHoldingBooking,
              element: <OwnerHoldingBooking />,
            },
            {
              path: Path.OwnerHoldingBookingCancel,
              element: <OwnerHoldingBookingCancel />,
            },
            {
              path: Path.OwnerHoldingRental,
              element: <OwnerHoldingRental />,
            },
          ],
        },
        {
          path: Path.TicketDetailsActivity,
          element: <RestrictedMsal isAuthenticated={true} fallback={<Navigate to={Path.Booking} replace />} />,
          children: [
            {
              index: true,
              element: <TicketDetailsActivity />,
            },
          ],
        },
        {
          path: Path.TicketDetailsPackage,
          element: <RestrictedMsal isAuthenticated={true} fallback={<Navigate to={Path.Booking} replace />} />,
          children: [
            {
              index: true,
              element: <TicketDetailsPackage />,
            },
          ],
        },
        {
          path: Path.TicketDetailsSingle,
          element: <RestrictedMsal isAuthenticated={true} fallback={<Navigate to={Path.Booking} replace />} />,
          children: [
            {
              index: true,
              element: <TicketDetailsSingle />,
            },
          ],
        },
        {
          path: '*',
          element: <LayoutNavigationError404 />,
        },
      ],
    },
  ])
